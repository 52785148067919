import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeI.vue'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name:'Home',
			component: Home
		},{
			path: '/recipe/:slug',
			name: 'Recipe',
			component: () => import('../views/RecipeI.vue')
		},
		{
			path: '/about',
			component: () => import('../views/HomeI.vue')
		},
	],
})

export default router